import SimpleLightbox from 'simplelightbox';

const thumbnailGallery = document.querySelectorAll(
    '.gallery__slider__card--thumbnail'
);

if (thumbnailGallery) {
    const lightboxGallery = new SimpleLightbox(
        `.gallery__slider__card--thumbnail`,
        {}
    );
}
